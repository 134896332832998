<template>
    <div class="w_100 h_100 flexCloumn">
        <div class="w_100 headbar flexEnd borderbox" style="padding:0 10px 0 0;border:0;">
            <div style="">

              <el-button v-if="$store.state.pmid != 1" icon="el-icon-upload" style="margin-left:10px" size="small" type="text" title="导入班级性质"
                         @click="dialogShow = true">导入班级性质</el-button>
                <el-button icon="el-icon-download" style="margin-left:10px" size="small" type="text" title="下载就业分类表"
                    @click="downXls">下载就业分类表</el-button>

                <el-button v-if="$store.state.pmid == 1" icon="el-icon-download" style="margin-left:10px" size="small"
                    type="text" title="全部学校" @click="downloadAll">下载全部</el-button>

            </div>
            <el-button class="ml20" @click="initFullTables" size="middle" type="text" title="全屏展示表格，也可双击表格全屏展示"> <i
                    class="el-icon-full-screen" style="font-size:24px!important;"></i></el-button>
        </div>

        <div class="w_100 flexCloumn" id="myFullTable" @dblclick="initFullTables"
            style=" margin:0 auto;height:calc(100% - 50px); position: relative;background-color: #fff;">

            <div v-if="tableData.length > 0" class="w_100">
                <div style="font-size:25px !important;font-weight: bold; line-height: 50px; text-align: center;">
                    {{ year }}
                    年南京市中等职业学校毕业生分类表（就业调查专用）

                    <!-- <el-button @click="downXls" circle size="small" icon="el-icon-download"></el-button> -->
                </div>
                <div style="padding-bottom: 10px;">
                    学校名称：{{ $store.state.pmid == 1 && this.tpmid == 'all' ? '全部' : tableData[0].platform }}
                </div>
            </div>



            <el-table id="table" ref="table" :data="tableData" row-key="id" class="flex_1 mytable"
                      header-row-class-name="mytableheader"
                      stripe
                      border
                      show-summary
                      :summary-method="getSummaries"
                height="100%" style="width:fit-content;width:100%;">
                <el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
                <el-table-column label="学校" v-if="$store.state.pmid == 1" prop="platform" min-width="150"
                    align="center"></el-table-column>
                <el-table-column label="班级名称" prop="class_name" width="120" align="center"></el-table-column>
                <el-table-column label="班级性质" prop="bjxz" min-width="100" align="center"></el-table-column>
                <el-table-column label="专业名称" prop="major_name" min-width="120" align="center"></el-table-column>
                <el-table-column label="专业类别" prop="major_type" width="120" align="center"></el-table-column>
                <el-table-column label="学制" prop="xuezhi" width="90" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.xuezhi == '高职'">5年</span>
                        <span v-else>3年</span>
                    </template>
                </el-table-column>
                <el-table-column label="招生人数" prop="zs_num" width="90" align="center"></el-table-column>
                <el-table-column label="毕业人数" prop="bys_num" width="90" align="center"></el-table-column>
                <el-table-column label="就业人数" prop="jiuye_num" width="90" align="center"></el-table-column>
                <el-table-column label="待业人数" prop="daiye_num" width="90" align="center"></el-table-column>
                <el-table-column label="机关及企、事业单位就业人数" prop="zhijie_num" width="180" align="center"></el-table-column>
                <el-table-column label="创业人数" prop="chuangye_num" width="90" align="center"></el-table-column>
                <el-table-column label="参军人数" prop="canjun_num" width="90" align="center"></el-table-column>
                <el-table-column label="国内升学人数" prop="gn_sx_num" width="90" align="center"></el-table-column>
                <el-table-column label="国外留学人数" prop="gw_sx_num" width="90" align="center"></el-table-column>



                <el-table-column fixed="right" label="操作" width="80" v-if="$store.state.pmid > 1">
                    <template slot-scope="scope">
                        <el-button @click="deleteRow(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- <div style="text-align:right;padding-top: 10px;">
                        <el-pagination background @current-change="handlePageChange" :current-page="page.current_page"
                            :page-size="page.per_page" :total="page.count"
                            layout="total, prev, pager, next"></el-pagination>
                    </div> -->



        </div>
      <el-dialog class="cus_dialog " title="班级性质" :visible.sync="dialogShow" :close-on-click-modal="false"
                 width="600px" height="30%">
        <div style="line-height: 50px;">
          <a href="/xls/班级性质导入模板.xlsx" target="_blank">班级性质导入模板下载（excel/xls）</a>
        </div>
        <div style="line-height: 50px; color:orangered">
          请严格按照模板格式填入正确的数值
        </div>

        <div style="text-align:right">
          <el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadTemp"
                     :on-success="uploadFiles" :show-file-list="false" name="image" accept=".xls,.xlsx">
            <el-button icon="el-icon-upload" type="primary" style="margin-left:100px"
                       size="small">选择文件</el-button>
          </el-upload>
        </div>
      </el-dialog>
    </div>
</template>

<script>

export default {
    props: ["tpmid", "all"],
    components: {

    },
    data() {
        return {
            tableData: [],
            page: {
                count: 0,
                current_page: 1,
                per_page: 20,
                total_page: 0
            },
            year: sessionStorage.getItem("year"),
          dialogShow: false,
        }
    },
    mounted() {
        this.getData()
    },
    watch: {
      tableData: {
        immediate: true,
        handler() {
          setTimeout(() => {
            const tds = document.querySelectorAll('#table .el-table__footer tr>td');
            tds[0].colSpan = this.tpmid ? 7 : 6;
            tds[0].style.textAlign = 'center'
            tds[1].style.display = 'none'
            tds[2].style.display = 'none'
            tds[3].style.display = 'none'
            tds[4].style.display = 'none'
            tds[5].style.display = 'none'
            if (this.tpmid) {
              tds[6].style.display = 'none'
            }
          }, 0)
        },
      }
    },
    methods: {

        getData() {
            let data = { tpmid: this.tpmid, all: this.all ? 1 : 0 }
            this.$http.post("/api/get_jiuye_fenleitable2", data).then(res => {
                this.tableData = res.data


            })
        },
        handlePageChange(page) {
            this.page.current_page = page;
            this.getData();
        },
        deleteRow(e) {

            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post("/api/jiuye_fenleitable_delete", { id: e.id }).then(res => {
                    this.getData()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });

                })
            }).catch(() => {

            });




        },
        downXls() {
            let data = { tpmid: this.tpmid, all: this.all ? 1 : 0 }
            this.$http.post("/api/export_bysflb", data).then(res => {
                if (res.data.url) {
                    window.open(res.data.url, "_blank")
                }

            })
        },
        downloadAll() {
          this.$http.post('/api/export_jyfl_all').then(res => {
            if (res.data.url) {
              window.open(res.data.url, "_blank")
            }
          })
        },
        getSummaries(param) {
          const { columns, data } = param;
          const sums = [];
          columns.forEach((column, index) => {
            if (index === 0) {
              sums[index] = '汇总';
              return;
            }
            const values = data.map(item => Number(item[column.property]));
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
            }
          });
          return sums;
        },
        uploadFiles(e) {
          let loadInstance = this.$loading({
            lock: true,
            text: '导入中....',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)'
          })
          this.$http.post("/api/import_bjxz_xls", {
            url: e.src,
            filename: e.fileName,
          }).then(res => {
            loadInstance.close()

            if (res.data.errors && res.data.errors.length > 0) {
              let html = ''
              for (let error of res.data.errors) {
                html += "<div style='font-size:12px'>" + error.msg + "</div>"
              }
              this.$alert(html, `未导入数据${res.data.errors.length}条`, {
                dangerouslyUseHTMLString: true,
                showCancelButton: true,
                cancelButtonText:"下载未导入数据",
                distinguishCancelAndClose: true,
                beforeClose: (action, instance, done) => {
                  if (action === 'cancel') {
                    this.exportToExcel([{name:"未导入数据",fails: res.data.errors}])
                  }
                  done()
                }
              });
            } else {
              this.$message.success("导入成功")
              this.dialogShow = false
              this.getData()
            }

            this.dialogShow3 = false
            this.activeName = ""
            this.$nextTick(e => {
              this.activeName = "forth"
            })

          })
        }

    }

}

</script>

<style scoped></style>