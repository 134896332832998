<template>
    <div style="height: 100%; ">

        <div style="text-align: center; padding-bottom: 10px;" v-if="xuezhiList.length >= 1" @change="changeXuezhi">
            <el-radio-group v-model="xuezhiName">
                <el-radio-button label="" checked>全部学制</el-radio-button>

                <el-radio-button v-for="item in xuezhiList" :label="item" :key="city">{{ item }}</el-radio-button>
            </el-radio-group>
        </div>


        <div style="display: flex; flex-flow: row wrap;">
            <div id="yjChart1" class="chart">
            </div>
            <div id="yjChart2" class="chart">
            </div>
            <div id="yjChart3" class="chart">
            </div>
            <div id="yjChart4" class="chart">
            </div>
            <div id="yjChart5" class="chart">
            </div>
            <div id="yjChart6" class="chart">
            </div>
            <div id="yjChart7" class="chart">
            </div>
            <div id="yjChart8" class="chart">
            </div>
            <div id="yjChart9" class="chart" style="width:100%;height:400px;margin-bottom:30px">
            </div>
            <div id="yjChart10" class="chart" style="width:50%">
            </div>
            <div id="yjChart11" class="chart" style="width:50%">
            </div>
            <div id="yjChart12" class="chart" style="width:50%">
            </div>
            <div id="yjChart13" class="chart" style="width:50%">
            </div>
           
        </div>
        <div>
            <table class="dttable" v-if="MajorTongji.length>0&&$store.state.pmid==1">
                <tr>
                    <td>学校名称</td>
                    <td v-for="(mj,i) in MajorTongji[0].major_type" :key="i">{{ mj.name }}</td>
                </tr>
                <tr v-for="(sch,i) in MajorTongji" :key="i">
                    <td>{{ sch.platform }}</td>
                    <td v-for="(mj,i) in sch.major_type" :key="i">{{ mj.data?mj.data.jiuye_num:'' }}</td>
                </tr>
            </table>
        </div>

        

    </div>
</template>

<script>

export default {
    props: ["tPmid"],
    components: {

    },
    data() {
        return {
            DataInfo: null,
            xuezhiList: [],
            xuezhiName: "",
            MajorTongji:[]
        }
    },
    mounted() {


        this.getData()

    },
    methods: {
        changeXuezhi() {
            this.getData()
        },
        getData() {
            let data = { tpmid: this.tPmid, xuezhi: this.xuezhiName }
            this.$http.post("/api/get_jiuye_tjtable", data).then(res => {

                this.DataInfo = res.data
                this.xuezhiList = res.data.xuezhi.map(e => { return e.class_xz })
                this.$nextTick(e => {
                    this.initPie()
                    this.initColumn()
                    this.initMcolumn()
                })
                this.getMajorTongji()
            })
        },
        getMajorTongji() {
            this.$http.post("/api/major_type_tongji",{xuezhi: this.xuezhiName}).then(res => {
                this.MajorTongji = res.data
                
            })
        },


        initPie() {

            let option = {
                title: {
                    text: '',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                // legend: {
                //     top: "10%",
                //     left: 'center'
                // },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['30%', '50%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            normal: {
                                formatter: '{b}{c}人({d}%)',
                                textStyle: {
                                    fontWeight: 'normal',
                                    fontSize: 12
                                }
                            }
                        },
                        data: [

                        ],
                        color: ['#2469f3', '#cccc99', '#ff6666', '#cccc00', '#996699', '#333333'],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };

            //就业率
            let percent = Math.round(this.DataInfo.jiuye_num / this.DataInfo.bys_num * 10000) / 100

            let xdata = [{ value: percent, name: '就业率' }, { value: 100 - percent, name: '其他' }];
            option.series[0].name = "就业率"
            option.series[0].data = xdata;
            option.title.text = "就业率"
            this.$echarts.init(document.getElementById('yjChart1')).setOption(option);


            percent = Math.round(this.DataInfo.duikou_num / this.DataInfo.bys_num * 10000) / 100

            xdata = [{ value: percent, name: '对口率' }, { value: 100 - percent, name: '不对口' }];
            option.series[0].name = "对口就业率"
            option.series[0].data = xdata;
            option.title.text = "对口就业率"
            this.$echarts.init(document.getElementById('yjChart2')).setOption(option);

            //就业去向
            xdata = this.DataInfo.quxiang.map(e => { return { value: e.n, name: e.quxiang } });

            option.series[0].name = "就业去向"
            option.series[0].data = xdata;
            option.title.text = "就业去向分组"
            this.$echarts.init(document.getElementById('yjChart3')).setOption(option);
            //就业产业
            xdata = this.DataInfo.chanye.map(e => { return { value: e.n, name: e.chanye } });
            option.title.text = "就业产业分组"
            option.series[0].name = "就业产业"
            option.series[0].data = xdata;
            this.$echarts.init(document.getElementById('yjChart4')).setOption(option);

            //就业地域
            xdata = this.DataInfo.diyu.map(e => { return { value: e.n, name: e.diyu } });
            option.title.text = "就业地域分组"
            option.series[0].name = "就业地域"
            option.series[0].data = xdata;
            this.$echarts.init(document.getElementById('yjChart5')).setOption(option);
            //就业地点
            xdata = this.DataInfo.didian.map(e => { return { value: e.n, name: e.didian } });
            option.title.text = "就业地点分组"
            option.series[0].name = "就业地点"
            option.series[0].data = xdata;
            this.$echarts.init(document.getElementById('yjChart6')).setOption(option);

            //就业渠道
            xdata = this.DataInfo.qudao.map(e => { return { value: e.n, name: e.qudao } });
            option.title.text = "就业渠道分组"
            option.series[0].name = "就业渠道"
            option.series[0].data = xdata;
            this.$echarts.init(document.getElementById('yjChart7')).setOption(option);

            //就业渠道
            xdata = this.DataInfo.zhengshu.map(e => { return { value: e.n, name: e.zhengshu } });
            option.title.text = "资格证书情况"
            option.series[0].name = "资格证书情况"
            option.series[0].data = xdata;
            this.$echarts.init(document.getElementById('yjChart8')).setOption(option);



        },
        initMcolumn() {//多个柱子的图

            let xData = [], valueData = [[], [], []], pctData = [];

            let majorDic = {}
            let majors_bys = [];
            for (let item of this.DataInfo.majors_bys) {
                if (item.major_type != "总计") {
                    majors_bys.push(item)
                }
            }
            this.DataInfo.majors_bys = majors_bys

            let majors_jiuye = [];
            for (let item of this.DataInfo.majors_jiuye) {
                if (item.major_type != "总计") {
                    majors_jiuye.push(item)
                }
            }
            this.DataInfo.majors_jiuye = majors_jiuye

            let majors_duikou = [];
            for (let item of this.DataInfo.majors_duikou) {
                if (item.zhuanye_type != "总计") {
                    majors_duikou.push(item)
                }
            }
            this.DataInfo.majors_duikou = majors_duikou


            for (let item of this.DataInfo.majors_bys) {
                if (!majorDic[item.major_type]) {
                    majorDic[item.major_type] = { bys: item.bys_num, duikou: 0, jiuye: 0 }
                } else {
                    majorDic[item.major_type].bys = item.bys_num
                }
            }

            for (let item of this.DataInfo.majors_jiuye) {
                if (!majorDic[item.major_type]) {
                    majorDic[item.major_type] = { bys: item.jiuye_num, duikou: 0, jiuye: item.jiuye_num }
                } else {
                    majorDic[item.major_type].jiuye = item.jiuye_num
                }
            }

            for (let item of this.DataInfo.majors_duikou) {
                if (!majorDic[item.zhuanye_type]) {
                    majorDic[item.zhuanye_type] = { bys: item.n.n, duikou: item.n, jiuye: item.n }
                } else {
                    majorDic[item.zhuanye_type].duikou = item.n
                }
            }

            for (let k in majorDic) {
                xData.push(k)
                valueData[0].push(majorDic[k].bys)
                valueData[1].push(majorDic[k].jiuye)
                valueData[2].push(majorDic[k].duikou)
            }




            //计算就业率

            for (let i = 0; i < xData.length; i++) {
                let jyl = Math.round(valueData[1][i] / valueData[0][i] * 10000) / 100;
                let dkl = Math.round(valueData[2][i] / valueData[0][i] * 10000) / 100;
                let tdata = { name: xData[i], jyl, dkl, total: valueData[0][i], jycount: valueData[1][i], dkcount: valueData[2][i] }
                pctData.push(tdata)
            }
            pctData = pctData.sort((a, b) => { return b.jyl - a.jyl })

            let option = {
                title: {
                    text: "各专业就业率对口率统计表",
                    left: "center"
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    },
                    formatter: function (params) {
                        let dataIndex = params[0].dataIndex
                        return pctData[dataIndex].name + "<br />" +
                            "总人数：" + pctData[dataIndex].total + ",就业人数：" + pctData[dataIndex].jycount +
                            ",对口人数：" + pctData[dataIndex].dkcount + "<br />" +
                            "就业率：" + pctData[dataIndex].jyl + "%,对口率：" + pctData[dataIndex].dkl + "%"
                    }
                },
                legend: {
                    left: "center",
                    top: "5%",

                    data: ['就业率', '对口率']
                },
                grid: {
                    left: "5%",
                    right: "5%",
                    bottom: "20%"
                },

                xAxis: [
                    {
                        type: 'category',
                        data: pctData.map(e => { return e.name }),
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            rotate: 45, // 旋转角度为45度
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '百分比',

                    }
                ],
                color: ['#2469f3', '#cccc99', '#ff6666', '#cccc00', '#996699', '#333333'],
                series: [
                    {
                        name: '就业率',
                        type: 'bar',
                        barMaxWidth: "40",
                        data: pctData.map(e => { return e.jyl }),

                    },
                    {
                        name: '对口率',
                        type: 'bar',
                        barMaxWidth: "40",
                        data: pctData.map(e => { return e.dkl }),

                    }

                ]
            };

            this.$echarts.init(document.getElementById('yjChart9')).setOption(option);





        },
        initColumn() {

            let xData = [];
            let xValue = [];

            let option = {
                title: {
                    text: '',
                    left: 'center'
                },
                tooltip: {
                    show: true
                },
                color: ['#2469f3', '#cccc99', '#ff6666', '#cccc00', '#996699', '#333333'],
                xAxis: {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: [120, 200, 150, 80, 70, 110, 130],
                        type: 'bar',
                        barWidth: 30,
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        }
                    }
                ]
            };
            //就业合同
            let getPropValue = (arr, name, code) => {
                let value = 0;
                for (let item of arr) {
                    if (item[code] == name) {
                        value = item.n
                        break
                    }
                }
                return value
            }
            xData.push({ name: "未签合同", value: getPropValue(this.DataInfo.hetong, "未签合同", "hetong") })
            xData.push({ name: "1年及以内", value: getPropValue(this.DataInfo.hetong, "1年及以内", "hetong") })
            xData.push({ name: "1～2(含)年", value: getPropValue(this.DataInfo.hetong, "1～2(含)年", "hetong") })
            xData.push({ name: "2～3(含)年", value: getPropValue(this.DataInfo.hetong, "2～3(含)年", "hetong") })
            for (let item of xData) {
                if (item.value == NaN) {
                    item.value = 0;
                }
            }
            xData = xData.sort((a, b) => { return b.value - a.value })
            option.xAxis.data = xData.map(e => { return e.name })
            option.series[0].data = xData.map(e => { return e.value })
            option.title.text = "就业合同签订情况（人数）"

            this.$echarts.init(document.getElementById('yjChart10')).setOption(option);


            //起薪情况
            xData = [];
            xData.push({ name: "2020及以下", value: getPropValue(this.DataInfo.salary, "2020及以下", "salary") })
            xData.push({ name: "2020～3594", value: getPropValue(this.DataInfo.salary, "2020～3594", "salary") })
            xData.push({ name: "3595～4000", value: getPropValue(this.DataInfo.salary, "3595～4000", "salary") })
            xData.push({ name: "4001～5000", value: getPropValue(this.DataInfo.salary, "4001～5000", "salary") })
            xData.push({ name: "5000以上", value: getPropValue(this.DataInfo.salary, "5000以上", "salary") })
            for (let item of xData) {
                if (item.value == NaN) {
                    item.value = 0;
                }
            }
            xData = xData.sort((a, b) => { return b.value - a.value })
            option.xAxis.data = xData.map(e => { return e.name })
            option.series[0].data = xData.map(e => { return e.value })
            option.title.text = "起薪情况（平均起薪" + this.DataInfo.salary_avg + "）"

            this.$echarts.init(document.getElementById('yjChart11')).setOption(option);


            //社会保险情况（人数）
            xData = [];
            xData.push({ name: "没有社保", value: getPropValue(this.DataInfo.baoxian, "没有社保", "wuxian") })
            xData.push({ name: "三险", value: getPropValue(this.DataInfo.baoxian, "三险", "wuxian") })
            xData.push({ name: "五险", value: getPropValue(this.DataInfo.baoxian, "五险", "wuxian") })
            xData.push({ name: "三险一金", value: getPropValue(this.DataInfo.baoxian, "三险一金", "wuxian") })
            xData.push({ name: "五险一金", value: getPropValue(this.DataInfo.baoxian, "五险一金", "wuxian") })
            for (let item of xData) {
                if (item.value == NaN) {
                    item.value = 0;
                }
            }
            xData = xData.sort((a, b) => { return b.value - a.value })
            option.xAxis.data = xData.map(e => { return e.name })
            option.series[0].data = xData.map(e => { return e.value })
            option.title.text = "社会保险情况（人数）"

            this.$echarts.init(document.getElementById('yjChart12')).setOption(option);



            //就业满意度情况（人数）
            xData = [];
            xData.push({ name: "无法评估", value: getPropValue(this.DataInfo.manyidu, "无法评估", "manyidu") })
            xData.push({ name: "不满意", value: getPropValue(this.DataInfo.manyidu, "不满意", "manyidu") })
            xData.push({ name: "比较满意", value: getPropValue(this.DataInfo.manyidu, "比较满意", "manyidu") })
            xData.push({ name: "满意", value: getPropValue(this.DataInfo.manyidu, "满意", "manyidu") })
            xData.push({ name: "非常满意", value: getPropValue(this.DataInfo.manyidu, "非常满意", "manyidu") })
            for (let item of xData) {
                if (item.value == NaN) {
                    item.value = 0;
                }
            }
            xData = xData.sort((a, b) => { return b.value - a.value })
            option.xAxis.data = xData.map(e => { return e.name })
            option.series[0].data = xData.map(e => { return e.value })
            option.title.text = "就业满意度情况（人数）"

            this.$echarts.init(document.getElementById('yjChart13')).setOption(option);


        }


    }

}

</script>

<style scoped>
.chart {
    width: 33%;
    height: 300px;
}
</style>